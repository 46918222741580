exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-us-jsx": () => import("./../../../src/pages/about-us.jsx" /* webpackChunkName: "component---src-pages-about-us-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-contact-mentor-interest-jsx": () => import("./../../../src/pages/contact/mentor-interest.jsx" /* webpackChunkName: "component---src-pages-contact-mentor-interest-jsx" */),
  "component---src-pages-contact-questions-and-comments-jsx": () => import("./../../../src/pages/contact/questions-and-comments.jsx" /* webpackChunkName: "component---src-pages-contact-questions-and-comments-jsx" */),
  "component---src-pages-contact-support-query-jsx": () => import("./../../../src/pages/contact/support-query.jsx" /* webpackChunkName: "component---src-pages-contact-support-query-jsx" */),
  "component---src-pages-cookie-policy-jsx": () => import("./../../../src/pages/cookie-policy.jsx" /* webpackChunkName: "component---src-pages-cookie-policy-jsx" */),
  "component---src-pages-faqs-jsx": () => import("./../../../src/pages/faqs.jsx" /* webpackChunkName: "component---src-pages-faqs-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-privacy-notice-jsx": () => import("./../../../src/pages/privacy-notice.jsx" /* webpackChunkName: "component---src-pages-privacy-notice-jsx" */),
  "component---src-pages-sitemap-jsx": () => import("./../../../src/pages/sitemap.jsx" /* webpackChunkName: "component---src-pages-sitemap-jsx" */),
  "component---src-pages-terms-of-use-jsx": () => import("./../../../src/pages/terms-of-use.jsx" /* webpackChunkName: "component---src-pages-terms-of-use-jsx" */),
  "component---src-pages-volunteering-jsx": () => import("./../../../src/pages/volunteering.jsx" /* webpackChunkName: "component---src-pages-volunteering-jsx" */),
  "component---src-pages-ways-to-help-jsx": () => import("./../../../src/pages/ways-to-help.jsx" /* webpackChunkName: "component---src-pages-ways-to-help-jsx" */),
  "component---src-pages-what-we-do-jsx": () => import("./../../../src/pages/what-we-do.jsx" /* webpackChunkName: "component---src-pages-what-we-do-jsx" */)
}

