import common from './common'

const primary = {
  ...common.button,
  color: `white`,
  bg: `alpha`,
  ':hover': {
    color: `omegaLighter`,
    bg: `heading`,
  }
}

export default primary