const white = '#fff'
const gray = {
  200: '#edf2f7',
  300: '#e2e8f0',
  400: '#cbd5e0',
  500: '#a0aec0',
  600: '#718096',
  700: '#4a5568',
  800: '#333333',
  900: '#1a202c'
}
const orange = {
  100: '#fffaf0',
  200: '#feebc8',
  300: '#fbd38d',
  400: '#f6ad55',
  500: '#ed8936',
  600: '#dd6b20',
  700: '#c05621',
  800: '#9c4221',
  900: '#7b341e'
}
const yellow = {
  100: '#fffff0',
  200: '#fefcbf',
  300: '#faf089',
  400: '#f6e05e',
  500: '#ecc94b',
  600: '#d69e2e',
  700: '#b7791f',
  800: '#975a16',
  900: '#744210'
}
const green = {
  100: '#f0fff4',
  500: '#48bb78'
}
const red = {
  100: '#fff5f5',
  500: '#f56565'
}

//Primary
const alpha = {
  alphaLighter: orange[100],
  alphaLight: orange[300],
  alpha: orange[500],
  alphaDark: orange[600],
  alphaDarker: orange[800]
}

// Secondary
const beta = {
  betaLighter: yellow[100],
  betaLight: yellow[300],
  beta: yellow[500],
  betaDark: yellow[600],
  betaDarker: yellow[800]
}

// Reserved
const gamma = {}

// Reserved
const psi = {}

// Neutral
const omega = {
  omegaLighter: gray[200],
  omegaLight: gray[300],
  omega: gray[500],
  omegaDark: gray[600],
  omegaDarker: gray[800]
}

const colors = {
  text: gray[600],
  article: gray[700],
  heading: gray[800],

  ...alpha,
  ...beta,
  ...gamma,
  ...psi,
  ...omega,

  successLight: green[100],
  success: green[500],
  errorLight: red[100],
  error: red[500],

  white: white,
  background: `#f8f8f8`,
  contentBg: white,
  headerBg: white,
  footerBg: gray[800],
  subheaderBg: gray[300],
  imgBg: white,

  mute: gray[300],
  highlight: gray[200],

  toggleButton: gray[600],
  toggleButtonbg: white,

  modes: {
    dark: {
      // Reverse alpha
      alphaLighter: alpha.alphaDarker,
      alphaLight: alpha.alphaDark,
      alphaDark: alpha.alphaLight,
      alphaDarker: alpha.alphaLighter,

      // Reverse beta
      betaLighter: beta.betaDarker,
      betaLight: beta.betaDark,
      betaDark: beta.betaLight,
      betaDarker: beta.betaLighter,

      // Reverse omega
      omegaLighter: omega.omegaDarker,
      omegaLight: omega.omegaDark,
      omegaDark: omega.omegaLight,
      omegaDarker: omega.omegaLighter,

      text: gray[400],
      article: gray[300],
      heading: gray[200],

      background: gray[900],
      contentBg: gray[800],
      headerBg: gray[800],
      footerBg: gray[800],
      subheaderBg: gray[600],
      imgBg: `#f8f8f8`, 

      mute: gray[600],

      toggleButton: gray[400],
      toggleButtonbg: gray[800]
    }
  }
}

export default colors