const button = {
  display: `inline-flex`,
  // minWidth: `10rem`,
  alignItems: `center`,
  justifyContent: `space-evenly`,
  outline: 0,
  userSelect: `none`,
  WebkitTapHighlightColor: `transparent`,
  WebkitTouchCallout: `none`,
  WebkitUserSelect: `none`,
  KhtmlUserSelect: `none`,
  MozUserSelect: `none`,
  MsUserSelect: `none`,
  borderRadius: `full`,
  cursor: `pointer`,
  transition: `all 250ms ease`,
  fontFamily: `body`
}

const common = {
  button
}

export default common