import primary from './primary'
import secondary from './secondary'
import header from './header'

const button = {
  secondary,
  primary,
  header,
}

export default button