import common from './common'

const header = {
  ...common.button,
  color: `white`,
  bg: `alpha`,
  borderColor: `alpha`,
  borderWidth: `md`,
  borderStyle: `solid`,
  ':hover': {
    color: `alphaDarker`,
    bg: `alphaLight`,
    borderColor: `alphaLight`
  }
}

export default header