const tab = {
    bg: 'white',
    borderRadius: `full`,
    transition: `
      transform 250ms ease,
      box-shadow 250ms ease,
      color 250ms ease
    `,
    display: `flex`,
    px: 4,
    py: 4,
    alignItems:`flex-start`,
    justifyContent:'space-evenly',
    flexDirection: `column`,
    flex:'1'
  }
  
  export default tab