const primary = {
    bg: `contentBg`,
    borderRadius: `full`,
    transition: `
      transform 250ms ease,
      box-shadow 250ms ease,
      color 250ms ease
    `,
    display: `flex`,
    gap: 4,
    px: [3, 4],
    py: [4, 5],
    alignItems:[`stretch`,`center`],
    flexDirection: [`column`,`row`],
  }
  
  export default primary