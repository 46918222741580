import primary from './primary'
import comment from './comment'
import story from './story'
import sidebar from './sidebar'
import faq from './faq'
import membership from './membership'
import tab from './tab'

const cards = {
    primary,
    comment,
    story,
    sidebar,
    faq,
    membership,
    tab
}

export default cards