const story = {
    bg: `alpha`,
    color: 'white',
    borderRadius: `full`,
    transition: `
      transform 250ms ease,
      box-shadow 250ms ease,
      color 250ms ease
    `,
    display: `flex`,
    gap: 2,
    px: [3, 4],
    py: [4, 5],
    alignItems:`flex-start`,
    flexDirection: `column`,
  }
  
  export default story