const nav = {
    color: `article`,
    textDecoration: `none`,
    fontWeight: `body`,
    userSelect: `none`,
    ':active': {
      color: 'alpha'
    },
    ':hover': {
      color: 'alpha'
    }
  }
  
  export default nav