const membership = {
    bg: `white`,
    borderRadius: `full`,
    transition: `
      transform 250ms ease,
      box-shadow 250ms ease,
      color 250ms ease
    `,
    display: `flex`,
    alignItems: 'center',
    px: 2,
    py: 3,
    mb: 4,
    gap: 3,
  }
  
  export default membership