const sidebar = {
    bg: `white`,
    borderRadius: `full`,
    transition: `
      transform 250ms ease,
      box-shadow 250ms ease,
      color 250ms ease
    `,
    display: `flex`,
    px: 3,
    py: 4,
    mb: 4,
    alignItems:`flex-start`,
    flexDirection: `column`,
  }
  
  export default sidebar