const secondary = {
    display: `inline-flex`,
    alignItems: `center`,
    justifyContent: `space-evenly`,
    outline: 1,
    userSelect: `none`,
    WebkitTapHighlightColor: `transparent`,
    WebkitTouchCallout: `none`,
    WebkitUserSelect: `none`,
    KhtmlUserSelect: `none`,
    MozUserSelect: `none`,
    MsUserSelect: `none`,
    borderRadius: `full`,
    cursor: `pointer`,
    transition: `all 250ms ease`,
    fontFamily: `body`,
    fontSize: 0,
    color: `alpha`,
    bg: `alphaLighter`,
    borderColor: `alpha`,
    ':hover': {
        color: `omegaLighter`,
        bg: `omegaDark`,
        borderColor: `omegaDark`
  }
}

export default secondary